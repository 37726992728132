import { useEffect, useState } from 'react'
import classNames from 'classnames'
import About from '@/Shared/Contacts/About'
import Button from '@/Shared/Button'
import Card from '@/Shared/Contacts/Card'
import ContactEdit from './Edit'
import ContactPreview from '@/Pages/Contacts/Preview'
import Details from '@/Shared/Layouts/Details'
import Duplicates from '@/Shared/Contacts/Duplicates'
import FollowedContact from '@/Shared/Contacts/FollowedContact'
import Main from '@/Shared/Layouts/Main'
import PendingRevisions from '@/Shared/Contacts/PendingRevisions/Index'
import Relationships from '@/Shared/Contacts/Relationships/Index'
import SimilarContacts from '@/Shared/SimilarContacts'
import Tab from '@/Shared/Tabs/Tab'
import Tags from '@/Shared/Tags/Index'
import Timeline from '@/Shared/Timeline/Index'
import Transactions from '@/Shared/Contacts/Transactions/Index'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const ContactShow = ({ contact }) => {
  const { user } = usePage().props.auth
  const [contactEditing, setContactEditing] = useState(false)
  const [contactPreviewing, setContactPreviewing] = useState(null)
  const [dimensions, setDimensions] = useState({ width: window.innerWidth })
  const [tagsOpen, setTagsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('activity')

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const dismissLinkPROContact = (pro) => {
    router.post(route('contacts.dismiss'), {
      id: contact.id,
      followable_id: pro.id,
    })
  }

  const linkPROContact = (pro) => {
    router.post(route('contacts.follow'), {
      id: contact.id,
      followable_id: pro.id,
      followable_type: pro.type,
    })
  }

  const previewContact = (id) => {
    setContactPreviewing(null)

    setTimeout(() => {
      axios.get(route('pros.show', id)).then((response) => {
        setContactPreviewing(response.data)
      })
    }, 300)
  }

  return (
    <Details columns={3}>
      <Details.Card>
        <Card contact={contact} onEdit={() => setContactEditing(true)} onOpenTags={() => setTagsOpen(true)} />

        {contact.following.length == 1 && (
          <div className="space-y-1.5 border-t border-gray-200 bg-blue-100 p-6">
            <div className="text-sm font-bold uppercase leading-none text-blue-500">Following updates for</div>
            <div className="space-y-1.5">
              {contact.following.map((followed) => (
                <FollowedContact contact={contact} followed={followed} key={followed.id} />
              ))}
            </div>
          </div>
        )}
      </Details.Card>

      <Details.LeftColumn>
        <About contact={contact} onOpenTags={() => setTagsOpen(true)} />
      </Details.LeftColumn>

      <Details.MiddleColumn>
        {contact.pending_revisions?.length > 0 && <PendingRevisions revisions={contact.pending_revisions} />}

        {contact.user_id != user.id && contact.following.length == 0 && contact.similar?.pros.length > 0 && (
          <SimilarContacts contacts={contact.similar.pros} onDismiss={(similarContact) => dismissLinkPROContact(similarContact)}>
            <SimilarContacts.Actions>
              {({ contact }) => (
                <Button type="button" theme="outline" onClick={() => linkPROContact(contact)}>
                  <div className="flex items-center gap-2">
                    <i className="fad fa-link-horizontal text-lg"></i>
                    Link
                  </div>
                </Button>
              )}
            </SimilarContacts.Actions>
          </SimilarContacts>
        )}

        <div className="flex h-full flex-col overflow-hidden bg-gray-100">
          <div className="flex h-full flex-col">
            <div className="relative z-0 flex w-full flex-col self-stretch overflow-hidden">
              <div className="flex h-full flex-col overflow-hidden">
                <div className="flex items-start justify-between px-4 pt-4 sm:px-6">
                  <ul
                    role="tablist"
                    className="list-reset mb-6 mt-2 flex flex-auto flex-wrap justify-start border-b border-gray-400 border-opacity-20"
                  >
                    <Tab active={activeTab === 'activity'} onClick={() => setActiveTab('activity')}>
                      Activity
                    </Tab>

                    <Tab active={activeTab === 'notes'} onClick={() => setActiveTab('notes')}>
                      Notes
                    </Tab>

                    {contact.transactions != undefined && dimensions.width >= 768 && (
                      <Tab active={activeTab === 'transactions'} onClick={() => setActiveTab('transactions')}>
                        {`Transactions (${contact.transactions?.length || 0})`}
                      </Tab>
                    )}
                  </ul>
                </div>

                <div className="flex flex-col overflow-hidden">
                  <div
                    id="activity"
                    className={classNames('flex flex-col overflow-hidden', {
                      hidden: activeTab !== 'activity',
                    })}
                  >
                    <Timeline />
                  </div>

                  <div
                    id="notes"
                    className={classNames('flex flex-col overflow-y-auto', {
                      hidden: activeTab !== 'notes',
                    })}
                  >
                    <Timeline type="note" emptyText="No notes have been added." />
                  </div>

                  {contact.transactions != undefined && dimensions.width >= 768 && (
                    <div
                      id="transactions"
                      className={classNames('flex flex-col overflow-y-auto', {
                        hidden: activeTab !== 'transactions',
                      })}
                    >
                      <Transactions
                        contact={contact}
                        onTransactionView={(transaction) => router.visit(route('transactions.show', transaction.id))}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactEdit record={contact} open={contactEditing} onClosed={() => setContactEditing(false)} />
        <Tags contact={contact} open={tagsOpen} onClosed={() => setTagsOpen(false)} />
        <ContactPreview
          contact={contactPreviewing}
          hideFooter={!contactPreviewing?.id}
          open={contactPreviewing != null}
          onContactChanged={previewContact}
          onClosed={() => setContactPreviewing(null)}
        >
          <ContactPreview.FooterActions>
            {({ contact }) =>
              contact.url && (
                <div className="flex justify-center">
                  <Button type="submit" theme="solid" onClick={() => window.open(contact.url, '_blank')}>
                    View Website
                  </Button>
                </div>
              )
            }
          </ContactPreview.FooterActions>
        </ContactPreview>
      </Details.MiddleColumn>

      <Details.RightColumn>
        <Duplicates />
        {contact.transactions != undefined && dimensions.width < 768 && (
          <Transactions
            contact={contact}
            onTransactionView={(transaction) => router.visit(route('transactions.show', transaction.id))}
            accordion
          />
        )}
        <Relationships type="Personal" contacts={contact.relationships?.filter((relationship) => !relationship.professional)} />
        <Relationships type="Professional" contacts={contact.relationships?.filter((relationship) => relationship.professional)} />
      </Details.RightColumn>
    </Details>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
ContactShow.layout = (page) => <Main title={page.props.contact.full_name} children={page} overflowHidden />

export default ContactShow
