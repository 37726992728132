import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Accordion from '@/Shared/Accordion'
import { useForm, usePage } from '@inertiajs/react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import PropertyListItem from '@/Shared/PropertyListItem'
import Checkbox from '@/Shared/Forms/Checkbox'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import helpers from '@/utils/helpers'
import TransactionDates from './Forms/Dates'
import { DatesContext } from './Forms/DatesContext'

export default function Edit({ contact, record, open, onClosed }) {
  const { checklists, errors: propErrors, propertyTypes, states } = usePage().props
  const { user } = usePage().props.auth
  let focusRef = useRef()

  const { dates, fillDates, transformDates } = useContext(DatesContext)
  const { data, setData, clearErrors, errors, setError } = useForm()
  const [type, setType] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [splitsEdited, setSplitsEdited] = useState(false)
  const [addresses, setAddresses] = useState(null)
  const associates = record?.contacts.filter((tc) => tc.contact_type === 'Associate')
  const myContact = record?.contacts.find((tc) => tc.is_me)

  useEffect(() => {
    let transaction = record || {}
    let initial = {
      id: transaction.id || null,
      contact: contact,
      contact_id: contact ? contact.id : null,
      checklist_id: transaction.checklist?.base_checklist_id || null,
      name: transaction.name || '',
      property_types:
        transaction.propertyTypes?.length > 0
          ? transaction.propertyTypes.map((s) =>
              Object.assign(
                {},
                {
                  label: s.name,
                  value: s.id.toString(),
                },
              ),
            )
          : null,
      states: transaction.states?.length > 0 ? states.filter((state) => transaction.states.find((s) => state.value == s.state)) : null,
      address_id: null,
      property_type_id:
        (transaction.property_type_id ? transaction.property_type_id.toString() : undefined) ||
        (transaction.propertyTypes.length == 1 ? transaction.propertyTypes[0].id.toString() : undefined),
      property_street: transaction.property_street,
      property_street2: transaction.property_street2,
      property_city: transaction.property_city,
      property_state: transaction.property_state,
      property_postal_code: transaction.property_postal_code,
      property_built_year: transaction.property_built_year || null,
      radon_inspection_results: transaction.radon_inspection_results,
    }

    if (associates?.length > 1) {
      initial.commission_splits = Array.from(associates, (associate) => ({
        id: associate.id,
        full_name: associate.full_name,
        commission_pct: associate.split,
        cap_pct: associate.cap_split,
        is_owner: associate.is_transaction_owner,
      }))
      initial.brokerage_split_auto_distribute = transaction.brokerage_split_auto_distribute
    }

    setData(initial)
  }, [record])

  useEffect(() => {
    if (open) {
      setData({ ...data, ...fillDates() })
      setAddresses(
        contact?.addresses
          .filter((address) => states.find((state) => state.value === address.state))
          .map((address) => ({
            value: address.id,
            label: address.address_inline,
          }))
          .concat({ value: 'new', label: 'New Address' }),
      )
    }
  }, [open])

  useEffect(() => {
    if (data.checklist_id) {
      setType(checklists.find((c) => c.value == data.checklist_id).label)
    }
  }, [data])

  useEffect(() => {
    Object.keys(propErrors).forEach(function eachKey(key) {
      setError(key, propErrors[key])
    })
  }, [propErrors])

  useEffect(() => {
    if (data.commission_splits) {
      let hasError = false
      let totalShare = data.commission_splits.reduce((carry, split) => carry + split.commission_pct, 0)
      let totalCap = data.commission_splits.reduce((carry, split) => carry + split.cap_pct, 0)

      if (totalShare != 100) {
        setError('commission_splits', `The total of all splits must add up to 100%. (Current: ${totalShare}%)`)
        hasError = true
      } else {
        clearErrors('commission_splits')
      }

      if (!data.brokerage_split_auto_distribute && totalCap != 100) {
        setError('brokerage_splits', `The total of all brokerage-side splits must add up to 100%. (Current: ${totalCap})`)
        hasError = true
      } else {
        clearErrors('brokerage_splits')
      }

      setDisabled(hasError)
      setSplitsEdited(
        associates.find((a) =>
          data.commission_splits.find((s) => s.id == a.id && (s.commission_pct != a.split || s.cap_pct != a.cap_split)),
        ),
      )
    }
  }, [data.commission_splits])

  useEffect(() => {
    if (addresses) {
      setData({
        ...data,
        address_id: addresses.length > 1 ? null : 'new',
        property_street: '',
        property_street2: '',
        property_city: '',
        property_state: '',
        property_postal_code: '',
      })
    }
  }, [addresses])

  const submit = (event) => {
    event.preventDefault()

    router.post(
      route('transactions.update', data.id),
      {
        ...data,
        ...transformDates(data),
        _method: 'put',
      },
      {
        onSuccess: (_) => {
          clearErrors()
          onClosed()
        },
      },
    )
  }

  return (
    <Fragment>
      <SlideOver
        focusRef={focusRef}
        footerActions={
          <Button type="submit" theme="solid" form="transaction-form" disabled={disabled}>
            Continue
          </Button>
        }
        show={open}
        size="max-w-xl"
        title={record.checklist.name + ' Transaction'}
        subTitle="Details"
        onClosed={onClosed}
        aboveMessages
      >
        <form id="transaction-form" className="pb-4" onSubmit={submit}>
          <div className="mb-3">
            <div className="mb-3">
              <label className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Name</label>
              <div className="text-xl font-medium">{data.name}</div>
            </div>

            <div className="-mx-4">
              {record.updated_at != record.created_at && <PropertyListItem label="Last Updated" value={record.updated_at} noBorder />}
              {<PropertyListItem label="Date Added" value={record.created_at} noBorder />}
            </div>
          </div>

          {(data.id || data.contact) && (
            <div className="-mx-4 sm:-mx-6">
              <Accordion>
                <Accordion.Items>
                  {associates?.length > 1 &&
                    (helpers.isAdminOrHR(user) || myContact?.is_transaction_owner || myContact?.permissions === 'admin') && (
                      <Accordion.Item active={true} warning={errors.commission_splits}>
                        <Accordion.Button>
                          <div className="text-lg font-medium">Splits</div>
                        </Accordion.Button>

                        <Accordion.Panel>
                          <div className="space-y-4">
                            <div>
                              <div className="mb-0.5 text-sm font-medium uppercase text-gray-500">
                                <span>
                                  Associate-Side <span className="text-red-600">*</span>
                                </span>
                              </div>

                              <div className="space-y-3">
                                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                                  {data.commission_splits?.map((split) => (
                                    <Fragment key={split.id}>
                                      <div className="flex divide-x divide-gray-300 bg-white">
                                        <span className="flex-1 bg-gray-200 bg-opacity-75 px-4 py-2 font-medium text-black">
                                          {split.full_name}
                                          <div className="text-[12px] uppercase leading-none text-gray-600">
                                            {split.is_owner ? 'Lead' : 'Supporting'} Associate Split
                                          </div>
                                        </span>
                                        <span className="flex w-48 items-center justify-center px-4 py-2 font-bold">
                                          <div className="relative flex w-full">
                                            <input
                                              autoComplete="off"
                                              autoCorrect="false"
                                              className={classNames(
                                                'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                                errors?.commission_splits
                                                  ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                                  : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                              )}
                                              type="number"
                                              value={split.commission_pct}
                                              onChange={(e) => {
                                                clearErrors('commission_split')
                                                setData({
                                                  ...data,
                                                  commission_splits: data.commission_splits.map((s) =>
                                                    s.id == split.id
                                                      ? {
                                                          ...s,
                                                          commission_pct: parseFloat(e.target.value),
                                                          cap_pct: data.brokerage_split_auto_distribute
                                                            ? parseFloat(e.target.value)
                                                            : s.cap_pct,
                                                        }
                                                      : s,
                                                  ),
                                                })
                                              }}
                                              onFocus={(e) => e.target.select()}
                                            />

                                            <span
                                              className={classNames(
                                                'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                                errors?.commission_splits
                                                  ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                                  : 'border-gray-300 bg-gray-200 text-gray-700',
                                              )}
                                            >
                                              <i className="fal fa-percent text-lg"></i>
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>

                                {errors.commission_splits && <div className="font-semibold text-red-600">{errors.commission_splits}</div>}
                              </div>
                            </div>

                            <div>
                              <div className="mb-3 text-sm font-medium uppercase text-gray-500">
                                <span>
                                  Brokerage-Side <span className="text-red-600">*</span>
                                </span>
                              </div>

                              <div className="mb-2 mt-1">
                                <Checkbox
                                  name="brokerage_split_auto_distribute"
                                  label="Distribute Automatically"
                                  description="CAP will be applied to all Associates according to their Associate-side split %."
                                  value={data.brokerage_split_auto_distribute}
                                  onChange={(checked) =>
                                    setData({
                                      ...data,
                                      brokerage_split_auto_distribute: checked,
                                      ...(checked
                                        ? {
                                            commission_splits: data.commission_splits.map((s) => ({
                                              ...s,
                                              cap_pct: s.commission_pct,
                                            })),
                                          }
                                        : {}),
                                    })
                                  }
                                />
                              </div>

                              <div className="space-y-3">
                                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                                  {data.commission_splits?.map((split, index) => (
                                    <Fragment key={split.id}>
                                      <div className="flex divide-x divide-gray-300 bg-white">
                                        <span className="flex-1 bg-gray-200 bg-opacity-75 px-4 py-2 font-medium text-black">
                                          {split.full_name}
                                          <div className="text-[12px] uppercase leading-none text-gray-600">
                                            {split.is_owner ? 'Lead' : 'Supporting'} Associate Brokerage Split
                                          </div>
                                        </span>
                                        <span className="flex w-48 items-center justify-center px-4 py-2 font-bold">
                                          <div className="relative flex w-full">
                                            <input
                                              name={`brokerage_split_${index}`}
                                              autoComplete="off"
                                              autoCorrect="false"
                                              className={classNames(
                                                'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                                data.brokerage_split_auto_distribute ? 'cursor-not-allowed bg-gray-200' : '',
                                                errors?.brokerage_splits
                                                  ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                                  : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                              )}
                                              spellCheck="false"
                                              type="number"
                                              value={split.cap_pct}
                                              onChange={(e) => {
                                                clearErrors('brokerage_splits')
                                                setData({
                                                  ...data,
                                                  commission_splits: data.commission_splits.map((s) => {
                                                    if (
                                                      s.id == split.id ||
                                                      (s.contact_id != undefined && s.contact_id == split.contact_id)
                                                    ) {
                                                      return {
                                                        ...s,
                                                        cap_pct: parseFloat(e.target.value),
                                                      }
                                                    }

                                                    return s
                                                  }),
                                                })
                                              }}
                                              onFocus={(e) => e.target.select()}
                                              disabled={data.brokerage_split_auto_distribute}
                                            />

                                            <span
                                              className={classNames(
                                                'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                                errors?.brokerage_splits
                                                  ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                                  : 'border-gray-300 bg-gray-200 text-gray-700',
                                              )}
                                            >
                                              <i className="fal fa-percent text-lg"></i>
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>

                                {errors?.brokerage_splits && <div className="font-semibold text-red-600">* {errors.brokerage_splits}</div>}
                              </div>
                            </div>

                            {splitsEdited && (
                              <Alert
                                heading="You have edited the splits for your Team"
                                subtext="Editing the Closing Worksheet and other actions will not be available until all affected Associates have approved the above changes."
                                type="warning"
                              />
                            )}
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                    )}

                  {['Buyer', 'Tenant'].some((t) => type === t) && !record?.property_type_id && (
                    <Accordion.Item warning={errors.states || states.length == 0}>
                      <Accordion.Button>
                        <div className="text-lg font-medium">General Information</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        <Select
                          error={errors.property_types}
                          label="Property Type(s)"
                          name="property_types"
                          options={propertyTypes.filter((type) => type.active)}
                          placeholder="Choose one"
                          value={data.property_types}
                          onChange={(selected) => {
                            setData({
                              ...data,
                              property_types: selected,
                              property_type_id:
                                selected?.length == 1
                                  ? selected[0].value
                                  : data.property_state
                                    ? selected?.find((type) => type.value === data.property_type_id)?.value
                                    : undefined,
                            })
                          }}
                          isClearable={false}
                          multiple
                          required
                        />

                        {data.checklist_id && (
                          <Fragment>
                            <Select
                              error={errors.states}
                              label="State(s)"
                              name="property_type"
                              options={states}
                              placeholder="Choose one or more"
                              value={data.states}
                              onChange={(selected) => setData({ ...data, states: selected })}
                              isClearable={false}
                              multiple
                              required
                            />

                            {states.length == 0 && (
                              <Alert
                                heading="You do not have any active licenses"
                                subtext="You either haven't added a license or your all of your licenses have expired and must be updated to continue."
                                type="danger"
                              />
                            )}
                          </Fragment>
                        )}
                      </Accordion.Panel>
                    </Accordion.Item>
                  )}

                  {type != null && (
                    <Accordion.Item active={!associates || associates.length == 1}>
                      <Accordion.Button>
                        <div className="text-lg font-medium">Property Information</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        {type === 'Seller' && addresses?.length > 1 && (
                          <Select
                            error={errors.address_id}
                            label="Address"
                            name="address_id"
                            options={addresses}
                            placeholder="Choose one"
                            value={data.address_id}
                            onChange={(selected) => setData({ ...data, address_id: selected?.value })}
                            isClearable={false}
                            required
                          />
                        )}

                        <div className="mb-4 space-y-4">
                          <Select
                            error={errors.property_type_id}
                            name="property_state"
                            label="Property Type"
                            options={propertyTypes.filter((type) => type.active || type.value == record?.property_type_id)}
                            placeholder="Select One"
                            value={data.property_type_id}
                            isClearable={false}
                            onChange={(selected) =>
                              setData({
                                ...data,
                                property_types: type === 'Seller' || record?.property_type_id ? [selected] : data.property_types,
                                property_type_id: selected?.value,
                              })
                            }
                            required
                          />

                          <TextInput
                            label="Street"
                            error={errors.property_street}
                            name="property_street"
                            value={data.property_street ?? ''}
                            onChange={(value) => setData({ ...data, property_street: value })}
                          />

                          <TextInput
                            label="Apartment/Suite/Unit #"
                            name="property_street2"
                            value={data.property_street2 ?? ''}
                            onChange={(value) => setData({ ...data, property_street2: value })}
                          />

                          <TextInput
                            label="City"
                            error={errors.property_city}
                            name="property_city"
                            value={data.property_city ?? ''}
                            onChange={(value) => setData({ ...data, property_city: value })}
                          />

                          <Select
                            label="State"
                            error={errors.property_state}
                            name="property_state"
                            placeholder="Select One"
                            options={states}
                            value={data.property_state}
                            isClearable={false}
                            onChange={(selected) => setData({ ...data, property_state: selected?.value })}
                          />

                          <TextInput
                            label="Zip Code"
                            error={errors.property_postal_code}
                            name="property_postal_code"
                            value={data.property_postal_code ?? ''}
                            onChange={(value) => setData({ ...data, property_postal_code: value })}
                          />
                        </div>

                        <TextInput
                          label="Year Property Built"
                          name="property_built_year"
                          type="number"
                          value={data.property_built_year}
                          error={errors.property_built_year}
                          onChange={(value) => setData({ ...data, property_built_year: value })}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  )}

                  <Accordion.Item>
                    <Accordion.Button>
                      <div className="text-lg font-medium">Closing Information</div>
                    </Accordion.Button>

                    <Accordion.Panel>
                      <TransactionDates data={data} setData={setData} errors={errors} />

                      {['Buyer', 'Seller'].some((type) => type.includes(data.type)) && (
                        <TextArea
                          label="Radon Inspection Results"
                          name="radon_inspection_results"
                          value={data.radon_inspection_results}
                          error={errors.radon_inspection_results}
                          rows="2"
                          onChange={(value) => setData({ ...data, radon_inspection_results: value })}
                          multiline
                        />
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion.Items>
              </Accordion>
            </div>
          )}
        </form>
      </SlideOver>
    </Fragment>
  )
}
