import { Fragment, useEffect } from 'react'
import AmountPercentInput from '@/Shared/Forms/AmountPercentInput'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import Radio from '@/Shared/Forms/Radio'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import TextArea from '@/Shared/Forms/TextArea'
import TimePicker from '@/Shared/TimePicker'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import classNames from 'classnames'

export default ({ step, transaction }) => {
  const { propertyTypes, states } = usePage().props
  const fields = step.settings.fields
  const { data, setData, clearErrors, errors, setError } = useForm()
  const closingWorksheetIsDisabled = transaction.payments.filter((payment) => !payment.credit && payment.paid).length > 0

  useEffect(() => {
    let newFields = [...fields]

    fields.forEach((field) => {
      if (field === 'commission_blre' || field === 'commission_cooperating' || field === 'commission_additional') {
        newFields.push(`${field}_pct`) // Add the new string with "_pct" appended
      }
    })

    let filledFields = newFields.reduce((fields, field) => {
      let filled = {
        [field]: data[field] != undefined ? data[field] : transaction[field] != undefined ? transaction[field] : null,
        closing_report_status:
          (errors.closing_report_status && (data.closing_report_status || transaction.closing_report_status)) || 'draft',
        commission_basis: data.commission_basis || transaction.commission_basis || 'net',
        commission_additional:
          data.commission_additional || transaction.commission_additional
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_additional || transaction.commission_additional), 2)
            : null,
        commission_additional_additional_pct:
          data.commission_additional_pct || transaction.commission_additional_pct
            ? parseFloat(data.commission_additional_pct || transaction.commission_additional_pct)
            : null,
        commission_blre:
          data.commission_blre || transaction.commission_blre
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_blre || transaction.commission_blre), 2)
            : null,
        commission_blre_pct:
          data.commission_blre_pct || transaction.commission_blre_pct
            ? parseFloat(data.commission_blre_pct || transaction.commission_blre_pct)
            : null,
        commission_cooperating:
          data.commission_cooperating || transaction.commission_cooperating
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_cooperating || transaction.commission_cooperating), 2)
            : null,
        commission_cooperating_pct:
          data.commission_cooperating_pct || transaction.commission_cooperating_pct
            ? parseFloat(data.commission_cooperating_pct || transaction.commission_cooperating_pct)
            : null,
        property_address: {
          property_type_id:
            data.property_type_id ||
            (transaction.property_type_id ? transaction.property_type_id.toString() : undefined) ||
            (transaction.propertyTypes.length == 1 ? transaction.propertyTypes[0].id.toString() : undefined),
          property_street: data.property_street || transaction.property_street,
          property_street2: data.property_street2 || transaction.property_street2,
          property_city: data.property_city || transaction.property_city,
          property_state: data.property_state || transaction.property_state,
          property_postal_code: data.property_postal_code || transaction.property_postal_code,
        },
        smoke_co_detector_inspection_fee: data.smoke_co_detector_inspection_fee || transaction.smoke_co_detector_inspection_fee,
        water_inspection_fee: transaction.water_inspection_fee || transaction.water_inspection_fee,
        other_reimbursements: transaction.other_reimbursements || transaction.other_reimbursements,
        other_reimbursements_notes: data.other_reimbursements_notes || transaction.other_reimbursements_notes,
        ...fillDates([
          'home_inspected_at',
          'home_inspection_contingency_date',
          'deadline_for_sale_of_buyers_property_contingency_date',
          'deadline_for_sellers_suitable_housing_contingency_date',
          'deadline_for_receipt_review_of_condo_documents_date',
          'appraisal_date',
          'smoke_detector_certification_date',
          'final_water_reading_date',
          'reinspection_date',
          'walk_through_date',
        ]),
      }[field]

      return { ...fields, ...{ [field]: filled } }
    }, [])

    setData({
      ...{ id: transaction.id },
      ...filledFields,
    })
  }, [transaction])

  // computed values
  const purchasePrice = () => {
    let total_purchase_price = data.total_purchase_price || transaction.total_purchase_price
    let closing_cost = data.closing_cost || transaction.closing_cost

    return data.commission_basis === 'net'
      ? Helpers.parseCurrency(total_purchase_price) - Helpers.parseCurrency(closing_cost)
      : Helpers.parseCurrency(total_purchase_price)
  }

  const fillDates = (dates) =>
    dates.reduce((acc, date) => {
      acc[date] = data[date] || transaction[date] ? new Date(data[date] || transaction[date]) : null
      return acc
    }, {})

  const transformDates = (dates) =>
    dates.reduce((acc, date) => {
      if (data[date]) {
        acc[date] = data[date].toLocaleString('en-US', { timeZone: 'America/New_York' })
      }
      return acc
    }, {})

  const updateDate = (newDate, currentDate) => {
    if (!newDate) {
      return currentDate
    } else if (!currentDate) {
      newDate.setHours(9)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      return newDate
    }

    let datetime = new Date(newDate)
    datetime.setHours(currentDate.getHours())
    datetime.setMinutes(currentDate.getMinutes())
    datetime.setSeconds(0)

    return datetime
  }

  const submit = (event) => {
    event.preventDefault()

    let transformedData = {
      ...data,
      ...data.property_address,
      ...transformDates([
        'home_inspected_at',
        'home_inspection_contingency_date',
        'deadline_for_sale_of_buyers_property_contingency_date',
        'deadline_for_sellers_suitable_housing_contingency_date',
        'deadline_for_receipt_review_of_condo_documents_date',
        'appraisal_date',
        'smoke_detector_certification_date',
        'final_water_reading_date',
        'reinspection_date',
        'walk_through_date',
      ]),
    }

    delete transformedData.property_address

    router.post(
      route('transactions.update', data.id),
      {
        ...data,
        ...transformedData,
        _method: 'put',
      },
      {
        onSuccess: (_) => {
          clearErrors()
        },
        onError: (errors) => {
          setError(errors)

          if (errors.closing_report_status) {
            setData({ ...data, closing_report_status: transaction.closing_report_status })
          }
        },
      },
    )
  }

  return (
    <div className="mx-auto max-w-lg rounded-md border border-gray-300 bg-white p-4 shadow sm:p-6">
      <form className="space-y-4" onSubmit={submit}>
        {fields.find((field) => field === 'closing_report_status') && (
          <div className="mb-4">
            <div className="space-y-4">
              <label
                className={classNames(
                  'block text-sm uppercase',
                  errors.closing_report_status ? 'font-semibold text-red-600' : 'font-medium text-gray-500',
                )}
              >
                <span>Closing Worksheet Status:</span>
              </label>

              <div className="mx-auto max-w-[75%] space-y-4">
                <Radio
                  id="closing_report_status_draft"
                  name="closing_report_status_option"
                  label={<span className="text-xl font-semibold normal-case">Draft / Preliminary</span>}
                  value={data.closing_report_status === 'draft'}
                  onChange={() => setData({ ...data, closing_report_status: 'draft' })}
                />
                <Radio
                  id="closing_report_status_final"
                  name="closing_report_status_option"
                  label={<span className="text-xl font-semibold normal-case">Final</span>}
                  value={data.closing_report_status === 'final'}
                  onChange={() => setData({ ...data, closing_report_status: 'final' })}
                />
              </div>

              {errors.closing_report_status && (
                <div className="mt-1 font-medium text-red-600" dangerouslySetInnerHTML={{ __html: errors.closing_report_status }}></div>
              )}
            </div>
          </div>
        )}

        {fields.find((field) => field === 'closing_cost') && (
          <TextInput
            label={transaction.type === 'Buyer' ? 'Seller Concessions' : 'Closing Cost Credit to Buyer'}
            name="closing_cost"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.closing_cost}
            error={errors.closing_cost}
            onBlur={() =>
              setData({
                ...data,
                closing_cost: Helpers.formatDecimal(Helpers.parseCurrency(data.closing_cost), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                closing_cost: Helpers.sanitizeCurrencyInput(value),
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'total_purchase_price') && (
          <TextInput
            label="Total Purchase Price"
            name="total_purchase_price"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.total_purchase_price}
            error={errors.total_purchase_price}
            onBlur={() =>
              setData({
                ...data,
                total_purchase_price: Helpers.formatDecimal(Helpers.parseCurrency(data.total_purchase_price), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                total_purchase_price: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'escrow_deposit1') && (
          <TextInput
            label="Initial Escrow Deposit"
            name="escrow_deposit1"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.escrow_deposit1}
            error={errors.escrow_deposit1}
            onBlur={() =>
              setData({
                ...data,
                escrow_deposit1: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit1), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                escrow_deposit1: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'escrow_deposit2') && (
          <TextInput
            label="2nd Escrow Deposit"
            name="escrow_deposit2"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.escrow_deposit2}
            error={errors.escrow_deposit2}
            onBlur={() =>
              setData({
                ...data,
                escrow_deposit2: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit2), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                escrow_deposit2: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'commission_basis') && (
          <div className="mb-4 space-y-2">
            <label className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              <span>Commissions based on:</span>
            </label>

            <div className="flex justify-center space-x-4">
              <Radio
                id="commission_basis_gross"
                name="commission_basis"
                label="Gross Purchase Price"
                value={data.commission_basis === 'gross'}
                onChange={() => setData({ ...data, commission_basis: 'gross' })}
                disabled={closingWorksheetIsDisabled}
              />
              <Radio
                id="commission_basis_net"
                name="commission_basis"
                label="Net Purchase Price"
                value={data.commission_basis === 'net'}
                onChange={() => setData({ ...data, commission_basis: 'net' })}
                disabled={closingWorksheetIsDisabled}
              />
            </div>
          </div>
        )}

        {data.hasOwnProperty('commission_blre') && fields.find((field) => field === 'commission_blre') && (
          <AmountPercentInput
            label={`${transaction.type} Agent Compensation`}
            name="commission_blre"
            basis={purchasePrice()}
            value={data.commission_blre}
            percent={data.commission_blre_pct}
            error={errors.commission_blre}
            onChange={(value, percent) => setData((prevData) => ({ ...prevData, commission_blre: value, commission_blre_pct: percent }))}
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {data.hasOwnProperty('commission_additional') && fields.find((field) => field === 'commission_additional') && (
          <AmountPercentInput
            label="EXTRA Buyer Agent Compensation"
            name="commission_additional"
            basis={purchasePrice()}
            value={data.commission_additional}
            percent={data.commission_additional_pct}
            error={errors.commission_additional}
            onChange={(value, percent) =>
              setData((prevData) => ({ ...prevData, commission_additional: value, commission_additional_pct: percent }))
            }
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {data.hasOwnProperty('commission_cooperating') && fields.find((field) => field === 'commission_cooperating') && (
          <AmountPercentInput
            label={`${transaction.cooperating_party} Agent Compensation`}
            name="commission_cooperating"
            basis={purchasePrice()}
            value={data.commission_cooperating}
            percent={data.commission_cooperating_pct}
            error={errors.commission_cooperating}
            onChange={(value, percent) =>
              setData((prevData) => ({ ...prevData, commission_cooperating: value, commission_cooperating_pct: percent }))
            }
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {[
          { name: 'hiring_date', label: 'Hiring / Engagement Date', type: 'date' },
          { name: 'offer_to_purchase_date', label: 'Offer to Purchase Date', type: 'date', disabled: closingWorksheetIsDisabled },
          { name: 'purchase_and_sale_date', label: 'Purchase and Sale Date', type: 'date', disabled: closingWorksheetIsDisabled },
          { name: 'closing_at', label: 'Closing Date', type: 'date', disabled: closingWorksheetIsDisabled },
          { name: 'home_inspected_at', label: 'Home Inspection Date', type: 'datetime' },
          { name: 'home_inspection_contingency_date', label: 'Home Inspection Contingency Date', type: 'date' },
          { name: 'reinspection_date', label: 'Re-inspection Date', type: 'datetime' },
          { name: 'smoke_detector_certification_date', label: 'Smoke Detector Certification Date', type: 'date' },
          { name: 'final_water_reading_date', label: 'Final Water Reading Date', type: 'date' },
          {
            name: 'deadline_for_sale_of_buyers_property_contingency_date',
            label: 'Deadline for Sale of Buyer’s Property Contingency',
            type: 'date',
          },
          {
            name: 'deadline_for_sellers_suitable_housing_contingency_date',
            label: 'Deadline for Seller’s Suitable Housing Contingency',
            type: 'date',
          },
          {
            name: 'deadline_for_receipt_review_of_condo_documents_date',
            label: 'Deadline for Receipt and Review of Condo Documents',
            type: 'date',
          },
          { name: 'appraisal_date', label: 'Appraisal Date', type: 'date' },
          { name: 'mortgage_commitment_date', label: 'Mortage Commitment Date', type: 'date' },
          { name: 'walk_through_date', label: 'Walk Through Date', type: 'datetime' },
        ]
          .filter((field) => fields.find((f) => f === field.name))
          .map((field) => (
            <Fragment key={field.name}>
              <div className="mb-4">
                <label htmlFor={field.name} className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                  {field.label}
                </label>

                <DatePicker
                  name={field.name}
                  date={data[field.name] && new Date(data[field.name])}
                  error={errors[field.name]}
                  onChange={(date) => {
                    setData({
                      ...data,
                      [field.name]: field.type === 'datetime' ? (date ? updateDate(date, data[field.name]) : null) : date || null,
                    })
                  }}
                  disabled={field.disabled}
                />
              </div>

              <div className="mb-4">
                {field.type === 'datetime' && (
                  <TimePicker
                    id={field.name}
                    label={field.label.replace('Date', 'Time')}
                    value={data[field.name]}
                    onChange={(date) => setData({ ...data, [field.name]: updateDate(data[field.name], date) })}
                    disabled={!data[field.name]}
                    isClearable={true}
                  />
                )}
              </div>
            </Fragment>
          ))}

        {fields.find((field) => field === 'property_address') && (
          <div>
            <Heading>Property Address</Heading>

            <div className="space-y-4">
              <Select
                error={errors.property_type_id}
                classes="mb-4"
                name="property_state"
                label="Property Type"
                options={propertyTypes.filter((type) => type.active || type.value == transaction.property_type_id)}
                placeholder="Select One"
                value={data.property_address?.property_type_id}
                isClearable={false}
                onChange={(selected) =>
                  setData({
                    ...data,
                    property_address: {
                      ...data.property_address,
                      property_types: transaction.property_type_id ? [selected] : data.property_types,
                      property_type_id: selected?.value,
                    },
                  })
                }
                required
              />

              <TextInput
                label="Street"
                error={errors.property_street}
                name="property_street"
                value={data.property_address?.property_street ?? ''}
                onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_street: value } })}
                required
              />

              <TextInput
                label="Apartment/Suite/Unit #"
                name="property_street2"
                value={data.property_address?.property_street2 ?? ''}
                onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_street2: value } })}
              />

              <TextInput
                label="City"
                error={errors.property_city}
                name="property_city"
                value={data.property_address?.property_city ?? ''}
                onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_city: value } })}
              />

              <Select
                label="State"
                error={errors.property_state}
                name="property_state"
                options={states}
                placeholder="Select One"
                value={data.property_address?.property_state}
                isClearable={false}
                onChange={(selected) =>
                  setData({ ...data, property_address: { ...data.property_address, property_state: selected?.value } })
                }
              />

              <TextInput
                label="Zip code"
                error={errors.property_postal_code}
                name="property_postal_code"
                value={data.property_address?.property_postal_code ?? ''}
                onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_postal_code: value } })}
              />
            </div>
          </div>
        )}

        {fields.find((field) => field === 'property_built_year') && (
          <TextInput
            error={errors.property_built_year}
            label="Year Property Built"
            name="property_built_year"
            type="number"
            value={data.property_built_year}
            onChange={(value) => setData({ ...data, property_built_year: value })}
          />
        )}

        {fields.find((field) => field === 'radon_inspection_results') && (
          <TextArea
            label="Radon Inspection Results"
            name="radon_inspection_results"
            value={data.radon_inspection_results}
            error={errors.radon_inspection_results}
            rows="2"
            onChange={(value) => setData({ ...data, radon_inspection_results: value })}
            required
            multiline
          />
        )}

        {fields.find((field) => field === 'smoke_co_detector_inspection_fee') && (
          <TextInput
            label="Fee for Smoke Detector and CO Detector Inspection"
            name="smoke_co_detector_inspection_fee"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.smoke_co_detector_inspection_fee}
            error={errors.smoke_co_detector_inspection_fee}
            onBlur={() =>
              setData({
                ...data,
                smoke_co_detector_inspection_fee: Helpers.formatDecimal(Helpers.parseCurrency(data.smoke_co_detector_inspection_fee), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                smoke_co_detector_inspection_fee: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'water_inspection_fee') && (
          <TextInput
            label="Fee for Final Water Inspection and/or Bill"
            name="water_inspection_fee"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.water_inspection_fee}
            error={errors.water_inspection_fee}
            onBlur={() =>
              setData({
                ...data,
                water_inspection_fee: Helpers.formatDecimal(Helpers.parseCurrency(data.water_inspection_fee), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                water_inspection_fee: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'other_reimbursements') && (
          <Fragment>
            <TextInput
              label="Other Reimbursements"
              name="other_reimbursements"
              icon={<i className="fas fa-dollar-sign"></i>}
              value={data.other_reimbursements}
              error={errors.other_reimbursements}
              onBlur={() =>
                setData({
                  ...data,
                  other_reimbursements: Helpers.formatDecimal(Helpers.parseCurrency(data.other_reimbursements), 2),
                })
              }
              onChange={(value) =>
                setData((prevData) => ({
                  ...prevData,
                  other_reimbursements: value,
                }))
              }
              onFocus={(e) => e.target.select()}
              clearable
              disabled={closingWorksheetIsDisabled}
            />

            <TextArea
              label={<span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Notes for Other Fees:</span>}
              name="other_reimbursements_notes"
              value={data.other_reimbursements_notes}
              error={errors.other_reimbursements_notes}
              onChange={(value) => setData({ ...data, other_reimbursements_notes: value })}
            />
          </Fragment>
        )}

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  )
}
