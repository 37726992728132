import Button from '@/Shared/Button'
import { Fragment } from 'react'

export default ({ contact, onCreate, onTransactionView }) => {
  const { transactions } = contact
  return (
    <div className="flex flex-col overflow-y-auto px-6 pb-6">
      <div className="mb-4 flex justify-end">
        <Button theme="solid" onClick={() => onCreate()}>
          <i className="far fa-plus pr-1 text-sm"></i>
          <span>New</span>
        </Button>
      </div>

      {transactions?.length > 0 ? (
        <table className="w-full border border-gray-300 bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 pl-4 text-left font-medium">Name</th>

              <th width="200px" className="py-2 pl-3 text-center font-medium">
                Closing Date
              </th>

              <th width="50px"></th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-300">
            {transactions.map((transaction, index) => (
              <tr className="hover:cursor-pointer hover:bg-gray-100" key={index} onClick={() => onTransactionView(transaction)}>
                <td className="py-3 pl-4 text-left align-top">
                  <div className="text-lg font-medium text-blue-500 sm:text-base">{transaction.name}</div>

                  <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                    {transaction.status === 'Pending' && (
                      <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                        <span>Sale Pending</span>
                      </div>
                    )}

                    {['Archived', 'Lost'].indexOf(transaction.status) >= 0 && (
                      <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                        <span>{transaction.status}</span>
                      </div>
                    )}

                    {['On-Hold'].indexOf(transaction.status) >= 0 && (
                      <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                        <span>{transaction.status}</span>
                      </div>
                    )}

                    {['Compliance', 'Under Review'].indexOf(transaction.status) >= 0 && (
                      <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                        <span>{transaction.status}</span>
                      </div>
                    )}

                    {['Closed'].indexOf(transaction.status) >= 0 && (
                      <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                        <span>Closed</span>
                      </div>
                    )}

                    {transaction.completed_at ? (
                      <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                        <span>Completed</span>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="flex items-center justify-start gap-1.5">
                          <span className="inline-block rounded bg-blue-200 bg-opacity-40 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-blue-700">
                            <span className="whitespace-pre-line leading-none">{transaction.stage.name}</span>
                          </span>
                          <span className="inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                            {transaction.stage.elapsed}
                          </span>
                        </div>

                        {transaction.propertyTypes?.map((type, index) => (
                          <span
                            className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                            key={index}
                          >
                            <span>{type.name}</span>
                          </span>
                        ))}

                        {transaction.property_state ? (
                          <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm uppercase leading-none text-gray-800">
                            <span>{transaction.property_state}</span>
                          </span>
                        ) : (
                          transaction.states?.map((state, index) => (
                            <span
                              className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                              key={index}
                            >
                              <span>{state.state}</span>
                            </span>
                          ))
                        )}
                      </Fragment>
                    )}
                  </div>
                </td>

                <td className="py-2 pl-3 text-center">{transaction.closing_at && transaction.closing_date_formatted}</td>

                <td className="cursor-pointer whitespace-nowrap px-6 py-3 text-right font-medium">
                  <div className="flex items-center justify-end gap-3">
                    <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="p-4 text-center text-gray-700">No transactions have been added.</div>
      )}
    </div>
  )
}
