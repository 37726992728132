import { usePage } from '@inertiajs/react'
import { createContext, useEffect, useState } from 'react'

export const ContactContext = createContext({
  contact: Object,
  creating: Boolean,
  editing: Boolean,
  focusedField: String,
  roles: Array,
  searching: Boolean,
  settings: Object,
  onCreating: (name) => {},
  onEditing: (contact, fieldToFocus) => {},
  onSearching: (settings) => {},
  setCreating: (creating) => {},
  setEditing: (editing) => {},
})

export const ContactProvider = ({ children }) => {
  const { transaction } = usePage().props
  const [settings, setSettings] = useState({ group: transaction.type, roles: [] })
  const [contact, setContact] = useState(null)
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [focusedField, setFocusedField] = useState(null)
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (!creating && !editing) {
      setContact(null)
    }
  }, [creating, editing])

  const onCreating = (name) => {
    setEditing(false)
    setSearching(false)

    let fullName = name.split(' ')

    setContact({
      transaction_id: transaction.id,
      party_representing: settings.group ? [settings.group] : '',
      roles: settings.roles,
      first_name: fullName[0],
      last_name: name.substring(fullName[0].length).trim(),
    })

    setTimeout(() => {
      setCreating(true)
    }, 100)
  }

  const onEditing = (contact, fieldToFocus) => {
    setCreating(false)
    setSearching(false)
    setContact(contact)
    setFocusedField(fieldToFocus)

    setTimeout(() => {
      setEditing(true)
    }, 10)
  }

  const onSearching = (data) => {
    if (data) {
      let newSettings = { ...settings }

      if (data?.group && transaction.parties.some((item) => data?.group.includes(item))) {
        newSettings.group = data?.group.includes(transaction.type) ? transaction.type : transaction.cooperating_party
      } else {
        delete newSettings.group
      }

      if (data?.role) {
        newSettings.roles = [data.role]

        if (!data?.group && transaction.parties.some((item) => data?.role.includes(item))) {
          newSettings.group = data?.role.includes(transaction.type) ? transaction.type : transaction.cooperating_party
        }
      } else {
        delete newSettings.roles
      }

      setSettings(newSettings)
    }

    setSearching(Boolean(data))
  }

  const context = {
    contact,
    creating,
    editing,
    focusedField,
    searching,
    settings,
    onCreating,
    onEditing,
    onSearching,
    setCreating,
    setEditing,
  }

  return <ContactContext.Provider value={context}>{children}</ContactContext.Provider>
}

export const ContactConsumer = ContactContext.Consumer
