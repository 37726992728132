import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import Button from '@/Shared/Button'
import Stage from '@/Pages/Transactions/Stage'

export default function TransactionAccordionView({ contact, onCreate, onTransactionView }) {
  return (
    <AccordionCard
      title={`Transactions (${contact.transactions ? contact.transactions.length : 0})`}
      actions={
        contact.type === 'Contact' && (
          <Button theme="link" onClick={() => onCreate()}>
            <i className="far fa-plus pr-1 text-sm"></i>
            <span>Add</span>
          </Button>
        )
      }
      collapsed
    >
      {contact.transactions && contact.transactions.length > 0 ? (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {contact.transactions.map((transaction) => (
            <AccordionItem key={transaction.id} onClick={() => onTransactionView(transaction)}>
              <div className="font-semibold leading-tight text-gray-900">{transaction.name}</div>

              {['Compliance', 'Under Review'].find((status) => status === transaction.status) && (
                <div className="mt-1 inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                  <span>{transaction.status}</span>
                </div>
              )}

              {!transaction.completed_at && <Stage transaction={transaction} horizontal />}

              <div className="mt-1 flex flex-wrap gap-1.5">
                {['Archived', 'Lost'].indexOf(transaction.status) >= 0 && (
                  <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                    <span>{transaction.status}</span>
                  </div>
                )}

                {['On-Hold'].indexOf(transaction.status) >= 0 && (
                  <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                    <span>{transaction.status}</span>
                  </div>
                )}

                {['Closed'].indexOf(transaction.status) >= 0 && (
                  <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                    <span>Closed</span>
                  </div>
                )}

                {transaction.completed_at && (
                  <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                    <span>Completed</span>
                  </div>
                )}
              </div>
            </AccordionItem>
          ))}
        </div>
      ) : (
        <div className="text-gray-700">No transactions have been added.</div>
      )}
    </AccordionCard>
  )
}
