import { createRef, forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'

export const KEY_CODE_BACKSPACE = 8
export const KEY_CODE_ENTER = 13
export const KEY_CODE_SPACE = 32
export const KEY_CODE_UP = 38
export const KEY_CODE_DOWN = 40
export const KEY_CODE_SEMICOLON = 186
export const KEY_CODE_COMMA = 188

export default forwardRef(({ children, data, emails, error, name, onInput, onUpdate }, ref) => {
  const [open, setOpen] = useState(false)
  const [refs, setRefs] = useState(null)
  const [selected, setSelected] = useState(-1)
  const scrollRef = createRef()
  const [suggestions, setSuggestions] = useState(data)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue('')
    if (open) {
      ref.current.focus()
    }
  }, [emails])

  useEffect(() => {
    if (!open) {
      setValue('')
      setSelected(-1)
      setSuggestions(data)
    }
  }, [open])

  useEffect(() => {
    if (value) {
      if (!open) {
        setOpen(true)

        setRefs(
          suggestions.reduce((carry, suggestion) => {
            carry[suggestion.id] = createRef()
            return carry
          }, {}),
        )
      }

      let loweredValue = value.toLowerCase()
      setSuggestions(
        data.filter(
          (s) =>
            (s.contact?.first_name || s.first_name).toLowerCase().includes(loweredValue) ||
            (s.contact?.last_name || s.last_name).toLowerCase().includes(loweredValue) ||
            s.email?.toLowerCase().includes(loweredValue) ||
            (s.emails?.length > 0 && s.emails[0].email.toLowerCase().includes(loweredValue)),
        ),
      )

      setSelected(data.length > 0 ? data[0].id : -1)
    } else {
      setOpen(false)
    }
  }, [value])

  useEffect(() => {
    if (refs && refs[selected]?.current) {
      let container = scrollRef.current,
        el = refs[selected].current

      if (el.offsetTop + el.offsetHeight > container.offsetHeight || el.offsetTop < container.scrollTop) {
        container.scrollTo({
          top: refs[selected].current.offsetTop,
        })
      }
    }
  }, [selected])

  const add = () => {
    if (!value.includes('@') || !value.includes('.')) {
      setValue('')
      return
    }

    const email = {
      name: '',
      email: value.replace(',', '').replace(';', '').replace(' ', ''),
    }

    onUpdate(emails?.length > 0 ? emails.concat(email) : [email])

    setValue('')
  }

  const getSelectionText = () => {
    var text = ''
    var activeEl = document.activeElement
    var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null
    if (
      activeElTagName == 'textarea' ||
      (activeElTagName == 'input' &&
        /^(?:text|search|password|tel|url)$/i.test(activeEl.type) &&
        typeof activeEl.selectionStart == 'number')
    ) {
      text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd)
    } else if (window.getSelection) {
      text = window.getSelection().toString()
    }
    return text
  }

  const remove = (event, index) => {
    event.preventDefault()
    onUpdate(emails.filter((_, i) => i != index))
  }

  const removeLast = () => {
    if (value === '' && emails?.length > 0) {
      onUpdate(emails.length == 1 ? null : emails.slice(0, -1))
    } else {
      let selected = getSelectionText()
      if (selected) {
        setValue(value.replace(selected, ''))
      } else {
        setValue(value.slice(0, value.length - 1))
      }
    }
  }

  const onKeyPress = (event) => {
    if ([KEY_CODE_BACKSPACE, KEY_CODE_ENTER].indexOf(event.which) >= 0) {
      event.preventDefault()

      if (event.which === KEY_CODE_ENTER && suggestions?.length > 0 && open) {
        onContactSelected(suggestions.find((s) => s.id == selected))
      }

      return false
    }
  }

  const onKeyUp = (event) => {
    if ([KEY_CODE_ENTER, KEY_CODE_COMMA, KEY_CODE_SEMICOLON, KEY_CODE_SPACE].indexOf(event.which) >= 0) {
      event.preventDefault()

      if (event.which === KEY_CODE_ENTER && suggestions?.length > 0 && open) {
        onKeyPress(event)
      } else {
        add()
      }

      return false
    }

    if ([KEY_CODE_BACKSPACE].indexOf(event.which) >= 0) {
      removeLast()
    }
  }

  const onKeyDown = (event) => {
    if (event.which === KEY_CODE_BACKSPACE) {
      event.preventDefault()
    }

    if (open && [KEY_CODE_UP, KEY_CODE_DOWN].indexOf(event.which) >= 0) {
      let selectedIndex = suggestions.findIndex((s) => s.id == selected)
      const maxIndex = suggestions.length - 1

      selectedIndex += event.which === KEY_CODE_DOWN ? 1 : -1

      if (selectedIndex > maxIndex) {
        selectedIndex = maxIndex
      } else if (selectedIndex < 0) {
        selectedIndex = 0
      }

      if (selectedIndex !== selected) {
        setSelected(suggestions[selectedIndex].id)
      }
    }
  }

  const onContactSelected = (contact) => {
    if (emails?.length == 0 && contact.emails?.length == 0) {
      onUpdate([])
    } else {
      const email = {
        name: contact?.full_name || '',
        email: contact.email || contact.emails[0].email,
      }

      onUpdate(emails?.length > 0 ? emails.concat(email) : [email])
    }
  }

  return (
    <div className={classNames('flex items-center justify-between border-b border-gray-200 px-6 py-3', { 'bg-red-100': error })}>
      <div className="flex-grow">
        <div className="flex items-center space-x-2">
          <span className={classNames('leading-none', error ? 'font-bold text-red-600' : 'text-sm font-medium uppercase text-gray-500')}>
            {name.charAt(0).toUpperCase() + name.slice(1)}
          </span>

          <div className="relative flex flex-1 flex-wrap items-center gap-1">
            {emails?.map((address, index) => (
              <span
                className="inline-flex items-center whitespace-nowrap rounded-lg border border-gray-300 bg-gray-200 px-2 py-1 leading-none"
                style={{ maxWidth: '375px' }}
                title={address.name ? address.email : null}
                key={index}
              >
                <div className="truncate">{address.name || address.email}</div>

                <button
                  role="button"
                  className="hover:text-gold-500 ml-1 rounded-lg px-1 py-0.5 focus:text-red-700 focus:ring-2 focus:ring-inset focus:ring-red-700"
                  onClick={(event) => remove(event, index)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </span>
            ))}

            <div className="flex flex-grow items-center">
              <textarea
                ref={ref}
                rows="1"
                className="ml-1 w-full resize-none overflow-hidden border-none bg-transparent p-0 outline-none focus:ring-0"
                spellCheck="false"
                aria-label={name.charAt(0).toUpperCase() + name.slice(1)}
                autoComplete="false"
                autoCapitalize="off"
                autoCorrect="off"
                dir="ltr"
                role="combobox"
                aria-autocomplete="list"
                aria-haspopup={true}
                aria-expanded={true}
                aria-owns="contact-options-wrapper"
                aria-activedescendant="contact-options-list"
                value={value}
                // onBlur={() => add()}
                onChange={(e) => setValue(e.target.value)}
                onInput={onInput}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
              ></textarea>
            </div>

            <Transition
              id="contact-options-wrapper"
              className="absolute bottom-0 z-10 w-full"
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              {suggestions.length > 0 ? (
                <ul
                  ref={scrollRef}
                  id="contact-options-list"
                  className="absolute mt-1 max-h-60 w-full max-w-sm overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  role="listbox"
                  aria-orientation="vertical"
                  tabIndex={0}
                >
                  {suggestions
                    .filter((s) => Boolean(s.email) || s.emails?.length > 0)
                    .map((suggestion) => (
                      <li
                        ref={refs && refs[suggestion.id]}
                        key={suggestion.id}
                        className={classNames(
                          'relative cursor-default select-none px-4 py-3 text-gray-900',
                          suggestion.id == selected ? 'bg-gray-200' : '',
                        )}
                        value={suggestion}
                        role="option"
                        tabIndex={-1}
                        onMouseDown={() => onContactSelected(suggestion)}
                        onMouseEnter={() => setSelected(suggestion.id)}
                      >
                        <>
                          <span className="block truncate text-lg">{suggestion.full_name}</span>
                          <span className="text-base">{suggestion.email || suggestion.emails[0].email}</span>
                        </>
                      </li>
                    ))}
                </ul>
              ) : (
                <div className="absolute mt-1 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="relative cursor-default select-none bg-gray-200 px-4 py-3 text-gray-900">
                    <div className="text-lg font-medium">There are no contacts in this transaction found matching your input.</div>
                    <p>
                      Consider adding a contact to this transaction with the email you're trying to use. If you'd rather send a one-off
                      email without adding them to this transaction, simply enter the email you wish to use and press enter.
                    </p>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>

      {children}
    </div>
  )
})
