import { Fragment, useState } from 'react'
import AccordionView from './AccordionView'
import TabView from './TabView'
import TransactionCreate from '@/Pages/Transactions/Create'

export default function TransactionsIndex({ accordion, onTransactionView, ...rest }) {
  const { contact } = rest
  const [creating, setCreating] = useState(false)

  const handleCreating = () => {
    setCreating(true)
  }

  const handleClosed = (transaction) => {
    setCreating(false)

    if (transaction) {
      setTimeout(() => {
        onTransactionView(transaction || null)
      }, 300)
    }
  }

  return (
    <Fragment>
      {accordion ? <AccordionView onCreate={handleCreating} {...rest} /> : <TabView onCreate={handleCreating} {...rest} />}
      {contact.type === 'Contact' && <TransactionCreate contact={contact} open={creating} onClosed={handleClosed} />}
    </Fragment>
  )
}
